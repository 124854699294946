<template>
    <section id="UserList" class="container">
        <breadcrumbVue :items="crumbs"></breadcrumbVue>
        <h1>Add User</h1>
        <p>Enter new user information below. 
            Remember to select a role to set permission level for use of the tool. 
            Salespeople can simply use the tool. 
            Managers can add salespeople for their organization, and view quiz history for their organization. 
            Admins can access this dashboard.  
        When finished, click “add user.”</p>
        <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <!-- User Form -->
        <form @submit.prevent="submitAddUser">
            <div class="row my-3">
                <div class="col">
                    <button @click="returnToList" class="btn btn-secondary me-2">Cancel</button>
                    <button class="btn btn-primary" type="submit">
                        <span>Add User</span>
                        <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="inputFirstName" class="form-label">First Name</label>
                    <input id="inputFirstName" v-model="user.firstName" type="text" class="form-control" placeholder="First name" aria-label="First name" />
                </div>
                <div class="col">
                    <label for="inputLastName" class="form-label">Last Name</label>
                    <input id="inputLastName" v-model="user.lastName" type="text" class="form-control" placeholder="Last name" aria-label="Last name" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input id="inputEmail" v-model="user.email" type="email" class="form-control" placeholder="Email" aria-label="Email" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="selectProvider" class="form-label">Scent Provider</label>
                    <select id="selectProvider" v-model="user.provider" class="form-select" aria-label="Select Provider" :disabled="enableProviderByRole()">
                        <option value="0" selected>Select a provider...</option>
                        <option v-for="provider in providers" v-bind:key="provider.id" :value="provider.id">{{ provider.name }}</option>
                    </select>
                </div>
                <div class="col">
                    <label for="selectRole" class="form-label">Role</label>
                    <select id="selectRole" v-model="user.role" class="form-select" aria-label="Select role">
                        <option value="0" selected>Select a role...</option>
                        <option v-bind:value="groupConstants.user">Salesperson</option>
                        <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.manager">Manager</option>
                        <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.admin">Admin</option>
                    </select>
                </div>
            </div>
        </form>
        <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
</template>

<script>
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from '../../components/error-message-modal.vue';
import { mapGetters } from "vuex";
import { SendEmail } from '../../utils/email-service.js';
import { VUE_APP_VSE_USER_GROUP_ID, VUE_APP_VSE_MANAGER_GROUP_ID, VUE_APP_VSE_ADMIN_GROUP_ID } from "../../utils/constants.js";
//import _ from 'lodash';

export default {
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
        return {
            crumbs: [
                {
                    name: "Dashboard",
                    url: "/dashboard",
                },
                {
                    name: "Users",
                    url: "/users",
                },
                {
                    name: "Add User",
                    url: "/users/add",
                },
            ],
            groupConstants: {
                user: VUE_APP_VSE_USER_GROUP_ID,
                manager: VUE_APP_VSE_MANAGER_GROUP_ID,
                admin: VUE_APP_VSE_ADMIN_GROUP_ID,
            },
            user: {
                firstName: "",
                lastName: "",
                email: "",
                provider: {},
                role: "0",
                activeDirectoryMemberId: "",
                member: {},
                joined: null,
                exists: false,
                isProlitec: false,
            },
            savedUser: {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                provider: {},
                role: "0",
                activeDirectoryMemberId: "",
                member: {},
                joined: null,
            },
            currentExtendedUser: {},
            currentUserProvider: {},
            isLoading: false,
            isSaving: false,
            invalidInput: false,
            errorMessages: [],
            errorTitle: 'Error Adding User',
            showInvalidForm: false,
        };
    },
    emits: ["add-user-submit"],
    computed: {
        ...mapGetters({
            account: 'getCurrentUser',
            isUserSignedIn: 'isSignedInUser',
            getCurrentUserInRole: "getCurrentUserInRole",
            getUserByEmailAddress: "usersModule/getUserByEmailAddress",
            getDisplayRole: "usersModule/getDisplayRole",
            providers: "providersModule/getProviders",
            providerDetail: 'providersModule/getProviderById',
        }),
    },
    mounted() {
        this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
        console.log(this.currentExtendedUser);
        this.initAddUser();
    },
    methods: {
        initAddUser() {
            // if user = manager, set Provider and set to disabled
            if (this.getCurrentUserInRole('Task.Manager') && !this.getCurrentUserInRole('Task.Admin')) {
                console.log('is Manager...');
                this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
                this.user.provider = this.currentUserProvider.id;
            } else {
                this.user.provider = '0';
            }
        },
        enableProviderByRole() {
            console.log('current user in role? ' + this.getCurrentUserInRole('Task.Admin'));
            if (this.getCurrentUserInRole('Task.Admin')) {
                return false;
            } else {
                return true;
            }
        },
        async submitAddUser() {
            this.errorMessages = [];
            var isValid = true;

            // check for text in short, long description
            if (this.user.firstName == null || this.user.firstName.length == 0) {
                isValid = false;
                this.errorMessages.push("First Name is required.");
            }
            if (this.user.lastName == null || this.user.lastName.length == 0) {
                isValid = false;
                this.errorMessages.push("Last Name is required.");
            }
            // check for selected family, collection
            if (this.user.email == null || this.user.email.length == 0) {
                isValid = false;
                this.errorMessages.push("Email is required.");
            }
            if (this.user.provider == null || this.user.provider.length == 0) {
                isValid = false;
                this.errorMessages.push("Provider is required.");
            }
            if (this.user.role == null || this.user.role.length == 0 || this.user.role == '0') {
                isValid = false;
                this.errorMessages.push("Select a valid role.");
            }

            let existingVseUser = this.getUserByEmailAddress(this.user.email);
            let userVseExists = existingVseUser != null && existingVseUser.id != null && existingVseUser.id.length > 0 ? true : false;
            console.log(existingVseUser);
            console.log(this.user);
            // check if prolitec user
            if (this.user.email.split("@")[1] == "prolitec.com") {
                this.user.isProlitec = true;
            }
            if (isValid) {
                this.isSaving = true;
                // check if existing user in Azure AD
                this.$store.dispatch("usersModule/findExistingAdUser", this.user).then((adUser) => {
                    let promisesToCall = [];
                    console.log(adUser);
                    if (adUser != null && adUser.id.length > 12) {
                        console.log("AD user exists...");
                        this.user.activeDirectoryMemberId = adUser.id;
                        this.user.member = adUser;
                        
                        if (userVseExists) {
                            this.user.id = existingVseUser.id;
                            console.log("VSE user exists...");
                            // user exists in VSE...update VSE settings - promise 0, no return value needed
                            promisesToCall.push(this.$store.dispatch("usersModule/updateUserSettings", this.user));
                        } else {
                            console.log("VSE user does NOT exist...");
                            this.user.joined = new Date();
                            // promise 0, need return value
                            promisesToCall.push(this.$store.dispatch("usersModule/addUserSettings", this.user));
                        }
                        // user exists in AD...if user exists in Vse.UserSettings, update...else add
                        // promise 1 -- update user roles, no return value
                        promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.user));

                        Promise.all(promisesToCall).then((results) => {
                            console.log("Roles updated, vse User added/updated");
                            console.log(results);
                            var userId = '';
                            if (userVseExists) {
                                userId = this.user.id;
                            } else {
                                userId = results[0].id;
                            }
                            this.isSaving = false;
                            this.sendInviteEmail(this.user);
                            // redirect to view user with success message
                            this.$router.push("/users/" + userId);
                        });
                    } else {
                        console.log("AD user does NOT exist...");
                        // invite user and get ID
                        this.$store.dispatch("usersModule/inviteUser", this.user).then((newUser) => {
                            // user memberId to add to groups
                            this.user.activeDirectoryMemberId = newUser.id;
                            if (userVseExists) {
                                console.log("VSE user exists...");
                                this.user.id = existingVseUser.id;
                                // user exists in VSE...update - promise 0
                                promisesToCall.push(this.$store.dispatch("usersModule/updateUserSettings", this.user));
                                if (this.user.role != existingVseUser.role) {
                                    // update roles, promise 1 no return value needed
                                    promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.user));
                                }
                            } else {
                                console.log("VSE user does NOT exist...");
                                this.user.joined = new Date();
                                // promise 0
                                promisesToCall.push(this.$store.dispatch("usersModule/addUserSettings", this.user));
                                // promise 1, no return value needed
                                promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.user));
                            }
                            Promise.all(promisesToCall).then((results) => {
                                console.log("Roles updated, vse User added/updated");
                                // redirect to view user with success message
                                console.log(results);
                                var userId = '';
                                if (userVseExists) {
                                    userId = this.user.id;
                                } else {
                                    userId = results[0].id;
                                }
                                this.sendInviteEmail(this.user);
                                this.isSaving = false;
                                this.$router.push("/users/" + userId);
                            });
                        })
                        .catch((error) => {
                            console.error("API Error, could not add user. Please try again later.", error);
                            isValid = false;
                            this.errorMessages.push("API Error, could not add user. Please try again later.");
                            this.showInvalidForm = true;
                        });
                    }
                });
            } else {
                this.showInvalidForm = true;
            }
        },
        clearFormFields() {
            // reset form fields
            this.user.firstName = "";
            this.user.lastName = "";
            this.user.email = "";
            this.user.provider = "";
            this.user.role = "0";
        },
        onCloseErrorMessageModal() {
            console.log("close error modal");
            this.showInvalidForm = false;
            this.errorMessages = [];
        },
        returnToList() {
            this.$router.push('/users/');
        },
        async sendInviteEmail(user) {
        // send invite email
        var baseUrl = window.location.origin;
        var emailBody = `Dear ${user.firstName}, \n You have been invited to the Prolitec Virtual Scent Explorer application. Please visit our site at ${baseUrl} and select Sign In. If you do not already have a Microsoft account associated with this email address, you can create one.`;
        var emailInvite = {
            recipient: user.email,
            subject: 'You are invited to the Virtual Scent Expert Application',
            plainText: emailBody,           
        };
        var messageId = await SendEmail(emailInvite).then((response)=> {
            console.log(messageId);
            console.log(response);
        });

    }
    },
};
</script>
