<template>
    <section id="AdjectiveSelectedList">
        <div class="row my-5">
            <h2>Active Adjectives ({{ selectedAdjectiveCount }})</h2>
            <p id="AdjectiveSelectedListHelpText">Add up to 8 adjectives. At least 1 is required.</p>
            <ul class="list-unstyled card-columns">
            <li v-for="(selectedAdjective, index)  in selectedAdjectives" :key="selectedAdjective.id" @click.self="mode == 'view' ? null : editSelectedAdjective(selectedAdjective, index)" class="card px-3 py-2 mb-2">
                <!-- Weight column (optional) -->
                <!-- Name of Adjective -->
                <div class="link link-primary d-flex justify-content-between align-items-center">
                <div>
                    <input v-model="selectedAdjective.weight" type="text" class="form-control weight text-center px-1" style="width: 40px; display: inline-flex" key="selectedAdjective.id" :disabled="mode == 'view'" />
                    <span class="ms-2">{{ selectedAdjective.name }}</span>
                </div>
                <a v-if="mode != 'view'" @click="removeItemFromSelectedList(selectedAdjective.id)"><BIconTrashFill variant="danger" /></a>
                </div>
                <!--<router-link :to="{ name: 'AdjectiveDetail', params: { adjectiveId: adjective.id }}" class="link link-primary">{{ adjective.name }}</router-link>-->
                <!-- Action column (optional) -->
            </li>
        </ul>
        </div>
    </section>
</template>

<script>

export default {
  name: 'AdjectiveSelectedList',
  data() {
    return {
        activeAdjectiveIndex: 0
    }
  },
  props: {
    selectedAdjectives: {
        type: Array
    }, 
    mode: {
        type: String
    }
  },
  computed: {
    selectedAdjectiveCount() {
        return (null == this.selectedAdjectives ? 0 : this.selectedAdjectives.length);
    }
    // selectedAdjectives() {
    //     return this.$store.getters['fragrancesExtendedModule/getCurrentExtendedFragrance'].adjectives;
    // },
  },
  methods: {
    removeItemFromSelectedList(adjectiveId) {
        this.$emit('removeItem', adjectiveId);
    },
    clearFormFields() {
        this.adjective.id = '';
        this.adjective.name = '';
        this.adjective.weight = '';
    }
  }
};
</script>