<template>
  <header>
    <nav class="navbar navbar-expand-lg" role="navigation"
      aria-label="main navigation">
      <div class="container">
        <HeaderBarBrand :theme="this.currentTheme" />
        <!--<a class="navbar-brand">Navbar</a>-->
        <HeaderBarLinks />
      </div>
    </nav>
  </header>
</template>

<script>
import HeaderBarBrand from '@/components/header-bar-brand';
import HeaderBarLinks from '@/components/header-bar-links';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderBar',
  components: { HeaderBarBrand, HeaderBarLinks },
  computed: {
    ...mapGetters({
        currentTheme: 'uiModule/getCurrentTheme',
    })
  }
};
</script>
