<template>
    <section id="ProviderDetail" class="container">
      <div class="row mt-4">
          <breadcrumbVue :items="crumbs"></breadcrumbVue>
      </div>
      <div v-if="pageState != 'view'">
          <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
              <div class="container">
                  <span>Edit Provider: {{ currentProvider.name }}</span>
                  <span>
                      <button @click="showPageStateView" class="btn btn-secondary me-3">Cancel</button>
                      <button @click="validateAndSaveProvider" class="btn btn-primary">
                          <span>Save</span>
                          <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                  </span> 
              </div>
          </nav>
      </div> 
      <div v-else>
          <h1 style="margin-top:-.3em;">{{ currentProvider.name }}</h1>
      </div>
      <div v-if="showSaveSuccess == true">
          <div class="alert fade show alert-success" role="alert">
          Your changes were saved.
          </div>
      </div>


      <!-- FRAGRANCES -->
      <div class="row my-5">
          <h2>Fragrance Inventory</h2>
          <div v-if="pageState == 'edit'">
            <a class="link link-primary" @click="selectAllFragrances">Select All</a> |
            <a class="link link-primary" @click="deselectAllFragrances">Deselect All</a>
          </div>
          <ul class="list-unstyled card-columns">
            <li v-for="(fragrance) in allFragrances" :key="fragrance.id" class="p-2 mb-2">
                <div class="form-check form-switch">
                    <!-- Checkbox -->
                    <input class="form-check-input" :id="'flexSwitchCheckDefault_' + fragrance.id" type="checkbox" role="switch" @change="toggleFragranceSelection(fragrance.id)" :checked="isFragranceSelected(fragrance.id)" :disabled="pageState == 'view'">
                    <!-- Name of Fragrance -->
                    <label class="form-check-label notranslate" :for="'flexSwitchCheckDefault_' + fragrance.id">{{ fragrance.name }}</label>
                </div>
            </li>
        </ul>
      </div>
      <!-- END FRAGRANCES -->


      <!-- ACTIONS -->
      <div class="row my-5">
          <div class="col">
              <h2>Actions</h2>
              <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
          </div>
      </div>
  
      <!-- DELETE -->
      <div class="row my-5">
          <div class="col">
              <h2>Danger Zone</h2>
              <button @click="deleteProviderWarning" class="btn btn-danger">Delete</button>
          </div>
      </div>
  
      <!-- DELETE MODAL -->
      <div v-if="isDeleteModalVisible" class="modal fade show" id="myModal" style="display: block;">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title">Delete Provider</h4>
                      <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                          <span class="visually-hidden" aria-hidden="true" >×</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <p id="FragranceDeleteText">Are you sure you want to delete <strong>{{ currentProvider.name }}</strong>?</p>
                      <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-danger" @click="deleteFragrance" data-dismiss="modal">
                          <span>Confirm</span>
                          <span v-show="isDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
      <!-- END: DELETE MODAL -->
      <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
  </template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import {mapGetters } from 'vuex';

export default {
    name: 'ProviderDetail',
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Providers',
                url: '/providers'
        }
        ],
        pageState: 'view',
        errorMessages: [],
        errorTitle: 'Error Adding Provider',
        isLoading: false,
        isSaving: false,
        isDeleting: false,
        showSaveSuccess: false,
        showSaveError: false,
        showInvalidForm: false,
        isDeleteModalVisible: false,
        currentProvider: {
            fragrances: []
        },
        currentName: null,
        currentId: null,
        isEnforceUploads: false
    }
  },
  created() {
    console.log('Route ID: ' + this.$route.params.providerId);
    this.populateProvider();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
        allFragrances: 'fragrancesExtendedModule/getFragrancesExtended',
        getCurrentUserInRole: 'getCurrentUserInRole',
    }),  
  },
  methods: {
    populateProvider() {
        this.currentProvider = this.$store.getters['providersModule/getProviderById'](this.$route.params.providerId);
        if (this.currentProvider.fragrances == null) {
            this.currentProvider.fragrances = [];
        }
        console.log('init complete...');
        console.log(this.currentProvider);
        var newCrumb = { name: this.currentProvider.name, url: '/providers/' + this.$route.params.providerId };
        this.crumbs.push(newCrumb);
        return;
    },
    selectAllFragrances() {
        this.currentProvider.fragrances = this.allFragrances.map((allFragrances) => ({
            id: allFragrances.id
        }));
    },
    deselectAllFragrances() {
        this.currentProvider.fragrances = [];
    },
    isFragranceSelected(fragranceId) {
        if (this.currentProvider.fragrances != null && this.currentProvider.fragrances.length > 0) {
            let index = this.currentProvider.fragrances.findIndex(f => f.id == fragranceId);
            if (index > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    toggleFragranceSelection(fragranceId) { 
        console.log(fragranceId);
        let index = this.currentProvider.fragrances.findIndex(f => f.id == fragranceId);
        if (index > -1) {
            console.log('found...remove it');
            // it's found - remove it
            this.currentProvider.fragrances.splice(index, 1);
        } else {
            // add the fragrance
            console.log('not found...add it');
            this.currentProvider.fragrances.push({id: fragranceId});
        }
    },
    showPageStateEdit() {
        this.pageState = 'edit';
        this.showSaveSuccess = false; 
    },
    showPageStateView() {
        this.pageState = 'view';
    },
    async validateAndSaveProvider() {
        this.errorMessages = [];
        var isValid = true;
        // check for id
        if (this.currentProvider.id == null || this.currentProvider.id.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Id is required.');
        }
        // check for name
        if (this.currentProvider.name == null || this.currentProvider.name.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Name is required.');
        }

        if (this.currentProvider.fragrances == null || this.currentProvider.fragrances.length < 1) {
            isValid = false;
            this.errorMessages.push('At least 1 Fragrances is required.');
        }

        if (isValid == true) {
            this.isSaving = true;
            // update
            console.log('update provider...');
            await this.$store.dispatch('providersModule/updateProvider', this.currentProvider).then(() => {
            // add saved success message
            this.showSaveSuccess = true;
            this.isSaving = false;
            this.pageState = 'view';
            });
 
        } else {
            // show error message
            console.log(this.errorMessages);
            this.showInvalidForm = true;
        }
    },
    deleteProviderWarning() {
        // show modal
        this.showSaveSuccess = false;
        this.isDeleteModalVisible = true;
    },
    async deleteProvider() {
        let deletedProviderName = this.currentProvider.name;
        console.log('delete fragrance: ' + deletedProviderName);
        this.isDeleting = true;
        await this.$store.dispatch('providersModule/deleteProvider', this.currentProvider.id).then(() => {
            this.isDeleteModalVisible = false;
            this.isDeleting = false;
            this.$router.push('/providers/delete/' + deletedProviderName);
        });
    },
    closeDeleteModal() {
        this.isDeleteModalVisible = false;
    },
    onCloseErrorMessageModal() {
        console.log('close error modal');
        this.showInvalidForm = false;
        this.errorMessages = [];
    }
  }
}

</script>
