<template>
    <section id="QuestionDeleteView" class="container">
        <div class="row">
            <div class="col">
                <h1>Deleted {{ deletedItemName }}</h1>
                <p>{{ deletedItemName }} has been deleted.</p>
                <router-link to="/questions" class="link link-primary">Return to Questions</router-link>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'QuestionDelete',
  data() {
    return {
      deletedItemName:  '',
    }
  },
  mounted() {
    this.deletedItemName = this.$route.params.questionText;
    console.log(this.deletedItemName);
  }
}
</script>
