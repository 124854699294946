<template>
    <select v-if="getCurrentUserInRole('Task.Admin')" class="form-select" @change="navigateToRoute($event)">
        <option value="0">Jump to..</option>
        <option v-for="route in routes" v-bind:key="route.name" :value="route.name">{{ route.displayName }}</option>
    </select>
</template>
<script>
import {mapGetters } from 'vuex';

export default {
    name: 'AdjectiveAdminAdd',
    data() {
    return {
        formVisible: false,
        currentErrorMsg: '',
        isValidForm: true,
        routes: []
        }
  },
  mounted() {
    this.initRoutes();
  },
  computed: {
    ...mapGetters({
        getCurrentUserInRole: 'getCurrentUserInRole',
        account: 'getCurrentUser',
        isUserSignedIn: 'isSignedInUser'
    })
  },
  methods: {
    initRoutes() {
        // add all routes except for the current one
        let routeSource = [
        {
            name: 'FragrancesList',
            displayName: 'Fragrances'
            }, {
            name: 'QuestionsList',
            displayName: 'Questions'
            }, {
            name: 'UsersList',
            displayName: 'Users'
            }, {
            name: 'AdjectivesList',
            displayName: 'Adjectives'
            }, {
            name: 'ProvidersList',
            displayName: 'Providers'
            }, {
            name: 'InvitesList',
            displayName: 'Invites'
            }, {
            name: 'ResultsList',
            displayName: 'Quiz Results'
            } 
        ];

        let currentRoute = this.$route.name;
        this.routes = routeSource.filter(route => route.name != currentRoute);
        console.log(currentRoute);
        console.log(this.routes);
        console.log(routeSource);
    },
    navigateToRoute(event) {
        console.log(event);
        console.log(event.target.value);
        // navigate to Route based on route name
        let routeName = event.target.value;
        if (routeName != "0") {
            this.$router.push({ name: routeName });
        }
    }
  }
};
</script>
