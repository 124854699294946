<template>
    <div id="AddAdjective" class="d-flex my-3">
        <button class="btn btn-primary me-3" @click="toggleAdjectiveForm">Add</button>
        <!-- Inline template to add an adjective -->
        <form @submit.prevent="validateAndSaveAdjective" class="row gy-2 gx-3 align-items-center" v-show="formVisible">
            <div class="col-auto">
                <label class="visually-hidden" for="AdjectiveName">Name</label>
                <input id="AdjectiveName" v-model="adjective.name" class="form-control" type="text" placeholder="Name" aria-label="Name" />
                
            </div>
            <div class="col-auto">
                <input type="button" value="Cancel" id="CancelAddAdjective" class="btn btn-secondary me-3" @click.self="hideAdjectiveForm" />
                <button id="SaveAddAdjective" type="Submit" class="btn btn-primary" :disabled="isLoading">
                    <span v-show="isLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    <span>Save</span>
                </button>
                <span v-if="this.isValidForm == false" class="text-danger ms-3">{{ currentErrorMsg }}</span>
            </div>
            
        </form>
    </div>
</template>
<script>
import {mapGetters } from 'vuex';

export default {
    name: 'AdjectiveAdminAdd',
    data() {
    return {
        adjective: {
            id: '',
            name: ''
        },
        formVisible: false,
        currentErrorMsg: '',
        isValidForm: true
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    async validateAndSaveAdjective() {
        this.isValidForm = true;
        this.currentErrorMsg = '';
        if (this.adjective.name.length > 0) {
            await this.$store.dispatch('adjectivesModule/addAdjective', this.adjective).then(() => {
                this.$emit('showSaveSuccess');
                this.clearFormFields();
            });   
        } else {
            this.isValidForm = false;
            console.log('Needs name!');
            this.currentErrorMsg = 'Adjective name required.';
            // show inline error message
        }
    },
    toggleAdjectiveForm() {
        this.formVisible = this.formVisible == true ? false : true;
    },
    hideAdjectiveForm() {
        console.log('Hide adjective form');
        this.formVisible = false;
        this.currentErrorMsg = '';
        this.isValidForm = true;
    },
    clearFormFields() {
        this.adjective.id = '';
        this.adjective.name = '';
        this.currentErrorMsg = '';
        this.isValidForm = false;
    }
  }
};
</script>