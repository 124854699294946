<template>
    <section id="UserDeleteView" class="container">
        <div class="row">
            <div class="col">
                <h1>Deleted {{ deletedUserName }}</h1>
                <p>{{ deletedUserName }} has been deleted.</p>
                <router-link to="/users" class="link link-primary">Return to Users</router-link>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'QuestionDelete',
  data() {
    return {
        deletedUserName:  '',
    }
  },
  mounted() {
    this.deletedUserName = this.$route.params.userName;
    console.log(this.deletedUserName);
  }
}
</script>
