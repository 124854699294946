import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."

export default {
  namespaced: true,
  state() {
    return {
        axiosQuestionListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'Qx1ASOCU9bOCxWsMwdxtJzbwPiMPyvnaRdQAFz7gLBnLAzFuRCqB2Q==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseQuestionConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'QVreqv9SdGA-pysHMBOiaOaw6-DUqw2JwC3ek-k2ApItAzFuXgMQfA==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseQuestionConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'kbyc0OY3a6CpCljjCiynw9tc4p4Ooo1M9DibvAhiHfDGAzFu7251wQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosDeleteVseQuestionConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'d0XLWNKbFUSaryX7k9t0_DVg5f7f4sZ8T4_NJrB_3xPFAzFu6y-KNQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        questionList: [],
        currentQuestion: null,
        publishStateOptions: [ 'Draft', 'Published'],
        questionTypeOptions: [ 'Photo + Text', 'Multiple Choice', 'Exclusive Choice' ],
        isQuestionListCachable: true
    };
  },
  getters: {
        getQuestions(state) {
            console.log('Accessing getQuestions getter...');
            if (state.questionList != null && state.questionList.length > 0) {
                console.log('State detected in getter. Length: ' + state.questionList.length);
            }
            return state.questionList;
        },
        getActiveQuestions(state) {
            return state.questionList.filter(question => question.publishState == 'Published');
        },
      getQuestionById: (state) => (id) => {
        //console.log(state);
        //console.log(id);
          if (null != state.questionList) {
            return state.questionList.find(question => question.id === id);
          } else {
            return null;
          }   
      },
      getCurrentQuestion(state) {
        return state.currentQuestion;
      },
      totalCount: (state) => {
        if (state.questionList == null) {
            return 0;
        } else {
            return state.questionList.length;
        }        
      },
      publishedCount: (state) => {
        if (state.questionList == null) {
            return 0;
        } else {
            return state.questionList.filter(q => q.publishState == 'Published').length;
        }        
      },
      draftCount: (state) => {
        if (state.questionList == null) {
            return 0;
        } else {
            return state.questionList.filter(q => q.publishState == 'Draft').length;
        }  
      },
      getPublishedStateOptions(state) {
        return state.publishStateOptions;
      },
      getQuestionTypeOptions(state) {
        return state.questionTypeOptions;
      }
  },
  mutations: {
    SET_QUESTION_LIST(state, questionList) {
        console.log('Set questionList mutation...');
        console.log(questionList);
        state.questionList = questionList;
    },
    SET_CURRENT_QUESTION(state, currentQuestion) {
        console.log(currentQuestion);
        state.currentQuestion = currentQuestion;
    },
    CLEAR_CURRENT_QUESTION(state) {
        state.currentQuestion = null;
    },
    ADD_CURRENT_QUESTION_ANSWER(state, answer) {
        console.log('add answer to store object...' + answer);
        const index = state.currentQuestion.answers.findIndex(x => x.id === answer.id);
        if (index !== undefined && index > -1) {
            state.currentQuestion.answers[index] = answer;
        } 
        state.currentQuestion.answers.push(answer);
    },
    UPDATE_CURRENT_QUESTION_ANSWER(state, updatedAnswer) {
        console.log('update answer...' + updatedAnswer);
        const index = state.currentQuestion.answers.findIndex(x => x.id === updatedAnswer.id);
        if (index !== undefined) {
            state.currentQuestion.answers[index] = updatedAnswer;
        } 
    },
    REMOVE_CURRENT_QUESTION_ANSWER(state, index) {
        if (index >= 0 && index < state.currentQuestion.answers.length) {
            state.currentQuestion.answers.splice(index, 1);
        } 
    },
    ADD_QUESTION(state, question) {
        console.log('Add question mutation...');
        console.log(question);
        state.questionList.push(question); 
    },
    UPDATE_QUESTION(state, question) {
        console.log('Update question mutation...');
        console.log(question);
        const index = state.questionList.findIndex(x => x.id === question.id);
        if (index !== undefined) {
            state.questionList[index] = question;
        }
    },
    DELETE_QUESTION(state, questionId) {
        console.log('Delete fragranceExtended mutation...');
        console.log(questionId);
        const index = state.questionList.findIndex(x => x.id === questionId);
        if (index !== undefined) {
            state.questionList.splice(index, 1);
        } 
    },
  },
  actions: {
    async fetchQuestions({ commit, state }) {
        try {
            console.log('Fetch extended fragrances...');
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListQuestions',state.axiosQuestionListConfig)
            .then((response) => {
                if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                commit('SET_QUESTION_LIST', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
                console.error('API Error.',error);
                });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
            //commit('contentLoading', false);
            }
    },
    async addQuestion({ commit, state }, newQuestion) {
        try {
            //commit('contentLoading', true);
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateQuestion', newQuestion, state.axiosAddVseQuestionConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                newQuestion.id = response.data.id;
                commit('ADD_QUESTION', newQuestion);
                return response.data.id;
              }
            })
            .catch(error => {
                    console.error('API Error.',error);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateQuestion({ commit, state }, question) {
        try {
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateQuestion', question, state.axiosUpdateVseQuestionConfig)
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('UPDATE_QUESTION', question);
                return response.data;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteQuestion({ commit }, questionId) {
        console.log(questionId);
        try {
            return await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteQuestion', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'d0XLWNKbFUSaryX7k9t0_DVg5f7f4sZ8T4_NJrB_3xPFAzFu6y-KNQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: questionId } })
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('DELETE_QUESTION', questionId);
                return true;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            return false;
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
  }
};