<template>
  <section id="InviteList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Invites</h1>
            <p>To view past invitee information, click their name. 
                To issue a new invitation, click “invite user.” </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <!-- ACTIONS -->
    <div class="row my-5">
        <div class="col">
            <h2>Actions</h2>
            <button @click="showInviteModal" class="btn btn-primary">Invite User</button>
        </div>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Invite List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
        <thead>
            <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Provider</th>
            <th v-if="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')">Invited by</th>
            <th>Date Sent</th>
            <th>Date Redeemed</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="invite in invitesForCurrentUser" :key="invite.id">
                <td><a href="#" class="link link-primary" @click="showInviteDetailModal(invite.id)">{{ invite.name }}</a></td>
                <td>{{ invite.email }}</td>
                <td>{{ getNameForProviderId(invite.provider) }}</td>
                <td v-if="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')">{{ invite.salesperson }}</td>
                <td>{{ formatToShortDate(invite.inviteDate) }}</td>
                <td>{{ formatToShortDate(invite.responseDate) }}</td>
            </tr>
        </tbody>
        </table>
    </div>

    <!-- ADD INVITE MODAL -->
    <div v-if="isInviteModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Invite User</h4>
                    <button type="button" class="btn-close" @click="closeInviteModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label for="inputInviteName" class="form-label">Name</label>
                    <input id="inputInviteName" v-model="currentInvite.name" type="text" class="form-control" placeholder="Name" aria-label="Name">
                    <label for="inputInviteEmail" class="form-label">Email</label>
                    <input id="inputInviteEmail" v-model="currentInvite.email" type="email" class="form-control" placeholder="Email" aria-label="Email">
                    <!-- hide unnecessary fields 
                    <label for="selectProvider" class="form-label">Provider</label>
                    <select id="selectProvider" v-model="currentInvite.provider" class="form-select" aria-label="Select Provider" :disabled="enableProviderByRole()">
                        <option value="0" selected>Select a provider...</option>
                        <option v-for="provider in providers" v-bind:key="provider.id" :value="provider.id">{{ provider.name }}</option>
                    </select>

                    <label v-show="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')" for="inputInviteSalesperson" class="form-label">Invited by</label>
                    <input v-show="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')" id="inputInviteSalesperson" v-model="currentInvite.salesperson" type="text" class="form-control" :disabled="isSalespersonFieldDisabled == 1" placeholder="Salesperson Email" aria-label="Salesperson">
                    -->
                    <div class="row align-items-end justify-content-between">
                        <div class="col flex-grow-1">
                            <label for="inputInviteCode" class="form-label">Code</label>
                            <input id="inputInviteCode" v-model="currentInvite.code" type="text" class="form-control" placeholder="Code" aria-label="Code" />
                        </div>
                        <div class="col" style="width: initial;">
                            <button class="btn btn-success" @click="generateCode">Generate New Code</button>
                        </div>
                    </div>     
                    <ul v-if="isInvalidForm == true">
                        <li v-for="error in errorMessages" v-bind:key="error" class="text-danger">{{ error }}</li>
                    </ul>  
                    <!--<p id="AdjectiveEditId">{{ this.selectedAdjective != null && this.selectedAdjective.id != null ? this.selectedAdjective.id : '' }}</p>-->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="validateAndSaveInvite" data-dismiss="modal">
                        <span>Invite</span>
                        <span v-show="isInviteSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="closeInviteModal" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isInviteModalVisible" class="modal-backdrop fade show"></div>
    <!-- END: ADD INVITE MODAL -->

    <!-- INVITE DETAILS MODAL -->
    <div v-if="isInviteDetailModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ currentInvite.name }}</h4>
                    <button type="button" class="btn-close" @click="closeInviteDetailModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label for="inputInviteName" class="form-label">Name</label>
                    <input id="inputInviteName" v-model="currentInvite.name" type="text" class="form-control" placeholder="Name" aria-label="Name" disabled>
                    <label for="inputInviteEmail" class="form-label">Email</label>
                    <input id="inputInviteEmail" v-model="currentInvite.email" type="text" class="form-control" placeholder="Email" aria-label="Email" disabled>
                    <label for="inputInviteProvider" class="form-label">Provider</label>
                    <input id="inputInviteProvider" :value="getNameForProviderId(currentInvite.provider)" type="text" class="form-control" placeholder="Provider" aria-label="Provider" disabled>
                    <label v-show="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')" for="inputInviteSalesperson" class="form-label">Invited by</label>
                    <input v-show="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')" id="inputInviteSalesperson" v-model="currentInvite.salesperson" type="text" class="form-control" disabled placeholder="Salesperson Email" aria-label="Salesperson">
                    <div class="row align-items-end justify-content-between">
                        <div class="col flex-grow-1">
                            <label for="inputInviteCode" class="form-label">Code</label>
                            <input id="inputInviteCode" v-model="currentInvite.code" type="text" class="form-control" placeholder="Code" aria-label="Code" disabled />
                        </div>
                    </div>     
                    <!--<p id="AdjectiveEditId">{{ this.selectedAdjective != null && this.selectedAdjective.id != null ? this.selectedAdjective.id : '' }}</p>-->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeInviteDetailModal" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isInviteDetailModalVisible" class="modal-backdrop fade show"></div>
    <!-- END: INVITE DETAILS MODAL -->
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { mapGetters } from 'vuex';
import { generateRandomCode } from '../../utils/helpers.js';
import { formatToShortDate } from '../../utils/helpers.js';
import { SendEmail } from '../../utils/email-service.js';
import auth from "../../services/auth";

export default {
  name: 'InviteList',
  components: {
    breadcrumbVue,
    jumpNavigation,
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Invites',
                url: '/invites'
      }],
      invitesForCurrentUser: [],
      payload:  '',
      currentSort: '',
      currentSortDir: 'asc',
      currentExtendedUser: {},
      currentUserProvider: {},
      selectedProvider: null,
      isEditModalVisible: false,
      isInviteDetailModalVisible: false,
      isSalespersonFieldDisabled: true,
      isLoading: false,
      currentInvite: null,
      isInviteModalVisible: false,
      isInviteSaving: false,
      errorMessages: [],
      isInvalidForm: false,
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    // get from API
    this.initInvite();
    this.initListForCurrentUser();
  },
  computed: {
    account() {
        return auth.user;
    },
    ...mapGetters({
        allInvites: 'invitesModule/getInvites',
        allUsers: 'usersModule/getUsers',
        count: 'invitesModule/count',
        getCurrentUserInRole: 'getCurrentUserInRole',
        getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
        isSignedInUser: 'isSignedInUser',
        account: 'getCurrentUser',
        providers: "providersModule/getProviders",
        providerDetail: 'providersModule/getProviderById',
    })
  },
  methods: {
    initInvite() {
        // set salesperson to current user...
        // if user.role == salesperson, disable salesperson field
        console.log(this.account.username);
        this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
        console.log(this.currentExtendedUser);
        this.currentInvite = {
            salesperson: this.account.username
        };
        this.isSalespersonFieldDisabled = this.getCurrentUserInRole('Task.Admin') || this.getCurrentUserInRole('Task.Manager') ? false : true;
        if (this.getCurrentUserInRole('Task.Admin')) {
            this.currentInvite.provider = 'Prolitec';
        } else {
            console.log('is Manager...');
            this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
            console.log(this.currentExtendedUser.provider);
            this.currentInvite.provider = this.currentUserProvider.id;
        }
        console.log(this.currentUserProvider);
    },
    async initListForCurrentUser() {
        this.isLoading = true;
        await this.$store.dispatch("invitesModule/fetchInvites").then(() => {
            // set current extended user
            
            if (this.getCurrentUserInRole('Task.Admin')) {
                console.log('Show invites for admin.');
                this.invitesForCurrentUser = this.allInvites;
            } else if (this.getCurrentUserInRole('Task.Manager')) {
                console.log('Show invites for manager.');
                this.invitesForCurrentUser = this.allInvites.filter(invite => invite.provider == this.currentUserProvider.id);
            } else {
                console.log('Show invites for salesperson.');
                this.invitesForCurrentUser = this.allInvites.filter(invite => invite.salesperson == this.account.username);
            }
            this.isLoading = false;
            this.sortInvites();
        });
    },
    enableProviderByRole() {
        console.log('current user in role? ' + this.getCurrentUserInRole('Task.Admin'));
        if (this.getCurrentUserInRole('Task.Admin')) {
            return false;
        } else {
            return true;
        }
    },
    getNameForProviderId(providerId) {
        let _provider = this.providers.find(p => p.id == providerId);
        return _provider != null ? _provider.name : providerId;
    },
    sortInvites() {
        this.invitesForCurrentUser.sort( ( a, b) => {
            return new Date(a.inviteDate) - new Date(b.inviteDate);
        });
        return this.invitesForCurrentUser;
    },
    showInviteModal() {
        this.isInviteModalVisible = true;
        this.currentInvite.code = generateRandomCode(6);
    },
    closeInviteModal() {
        this.isInviteModalVisible = false;
    },
    showInviteDetailModal(inviteId) {
        this.isInviteDetailModalVisible = true;
        this.currentInvite = this.allInvites.find(invite => invite.id == inviteId);
    },
    closeInviteDetailModal() {
        this.isInviteDetailModalVisible = false;
    },
    generateCode() {
        this.currentInvite.code = generateRandomCode(6);
    },
    formatToShortDate(date) {
        return formatToShortDate(date);
    },
    async validateAndSaveInvite() {
        // validate invite
        this.errorMessages = [];
        var isValidInvite = true;

        if (this.currentInvite.name == null || this.currentInvite.name.length < 1) {
            isValidInvite = false;
            this.errorMessages.push('Name is required.');
        }

        if (this.currentInvite.email == null || this.currentInvite.email.length < 3) {
            isValidInvite = false;
            this.errorMessages.push('Email is required.');
        }

        if (this.currentInvite.provider == null || this.currentInvite.provider.length < 3) {
            isValidInvite = false;
            this.errorMessages.push('Provider is required.');
        }

        if (this.currentInvite.salesperson == null || this.currentInvite.salesperson.length < 3) {
            isValidInvite = false;
            this.errorMessages.push('Salesperson is required.');
        }

        if (this.currentInvite.code == null || this.currentInvite.code.length < 6) {
            isValidInvite = false;
            this.errorMessages.push('Code is required.');
        }
        // if valid, save
        console.log('Invite inbound? '  + isValidInvite);

        if (isValidInvite) {
            this.isInviteSaving = true;
            await this.inviteQuizUser(this.currentInvite).then(() => {
                this.isInviteSaving = false;
                this.closeInviteModal();
                this.initInvite();
            });
        } else {
            this.isInvalidForm = true;
            console.log(this.currentInvite);
        }

        // reset after saving
        //this.currentInvite = null;
    },
    async inviteQuizUser(invite) {
        console.log('Invite...');
        console.log(invite);
        // check to see if user exists in user-settings...
        //var userDetails = this.$store.getters["usersModule/getUserByEmailAddress"](invite.emailAddress);
        var existingUserDetails = this.allUsers.find(user => user.email === invite.email);
        console.log('User Details...');
        console.log(existingUserDetails);

        var userInvite = {
            email: invite.email,
            name: invite.name,
            code: invite.code,
            provider: invite.provider,
            salesperson: invite.salesperson,
            inviteDate: new Date()
        }
        // add invite
        await this.$store.dispatch("invitesModule/addInvite", userInvite).then((response) => {
            this.sendInviteEmail(invite);
            console.log(response);
        });
    },
    async sendInviteEmail(invite) {
        // send invite email
        var baseUrl = window.location.origin + '/invite';
        var emailBody = `Dear ${invite.name}, \n You have been invited to take the Prolitec Virtual Scent Explorer quiz. Please visit our site at ${baseUrl}, enter this email address, and the following code to access your quiz: ${invite.code}.`;
        var emailInvite = {
            recipient: invite.email,
            subject: 'You are invited to take the Virtual Scent Expert Quiz',
            plainText: emailBody,           
        };
        var messageId = await SendEmail(emailInvite).then((response)=> {
            console.log(messageId);
            console.log(response);
        });

    }
  }
};
</script>
