<template>
  <section id="AdjectiveAdd" class="container">
    <h1>Create Adjective</h1>
    <form @submit.prevent="validateAndSaveAdjective" class="row gy-2 gx-3 align-items-center">
        <!-- Adjective Form -->
        <div class="col">
            <label for="inputName" class="form-label">Name</label>
            <input id="inputName" v-model="adjective.name" type="text" class="form-control" placeholder="Name" aria-label="Name">
        </div>
        <div class="col">
            <button class="btn btn-primary" type="submit">Add Adjective</button>
        </div>
        <div>{{ this.payload }}</div>
    </form>
    <!-- COLORS -->

    <!-- ADJECTIVES -->
  </section>
</template>

<script>
//import breadcrumbVue from '../../components/breadcrumb.vue';
//import {mapGetters } from 'vuex';

export default {
    name: 'AdjectiveAdd',
    components: {
        //breadcrumbVue
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Adjectives',
                url: '/adjectives'
        }],
        adjective: {
            id: '',
            name: ''
        },
        payload:  '',
        isLoading: false
    }
  },
  methods: {
    validateAndSaveAdjective() {
        if (this.adjective.name.length > 0) {
            console.log('Show save success from add component');
            this.$store.dispatch('adjectivesModule/addAdjective', this.adjective).then(() => {
                // emit show success
                console.log('Show save success from add component');
                this.$emit('showSaveSuccess');
            });
            
        }
    }

  }
}
</script>
