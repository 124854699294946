import axios from 'axios';

var axiosVseSendEmailConfig = {
    headers: {
        'Content-Type': 'application/json',
        'x-functions-key':'Y4Xd7BGxwbn_cD4BG6_kYozkF02fqEskZDPTF9OxGrziAzFuEGklTA==',
        'Referrer-Policy': 'origin-when-cross-origin'
    }
};
var azureVseSendEmailUrl = 'https://prolitecdataservices.azurewebsites.net/api/vseEmailSend';

export async function SendEmail(email) {
    console.log('API -> sendEmail...');
    console.log(email);
    
    return await axios.post(azureVseSendEmailUrl, email, axiosVseSendEmailConfig)
    .then((response) => {
        //setFragranceItems(state, response.data)
        console.log(response);
        //commit('HERO_IMAGE_FILE_UPLOAD_SUCCESS', response.data);
        return response;
    })
    .catch(error => {
            console.error('API Error.',error);
            //context.commit('FILE_UPLOAD_FAILURE', false);
    });
  }