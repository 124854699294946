<template>
  <section id="UserView" class="container">
    <div class="row mt-4">
        <breadcrumbVue :items="crumbs"></breadcrumbVue>
    </div>
    <div v-if="pageState != 'view'">
        <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
            <div class="container">
                <span>Edit User: {{ currentUser.firstName + ' ' + currentUser.lastName }}</span>
                <span>
                    <button @click="showPageStateView" class="btn btn-secondary me-3">Cancel</button>
                    <button @click="validateAndSaveUser" class="btn btn-primary">
                    <span>Save</span>
                    <span v-show="isUserSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </span> 
            </div>
        </nav>
    </div> 
    <div v-else>
        <h1 style="margin-top:-.3em;">{{ currentUser.firstName + ' ' + currentUser.lastName }}</h1>
    </div> 
    
<div class="row">
        <div class="col">
          <label for="inputFirstName" class="form-label">First Name</label>
          <input id="inputFirstName" v-model="currentUser.firstName" type="text" class="form-control" placeholder="First name" aria-label="First name" :disabled="pageState == 'view'">
        </div>
        <div class="col">
          <label for="inputLastName" class="form-label">Last Name</label>
          <input id="inputLastName" v-model="currentUser.lastName" type="text" class="form-control" placeholder="Last name" aria-label="Last name" :disabled="pageState == 'view'">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="inputEmail" class="form-label">Email</label>
          <input id="inputEmail" v-model="currentUser.email" type="email" class="form-control" placeholder="Email" aria-label="Email" :disabled="pageState == 'view' || isProlitecUser">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="selectProvider" class="form-label">Scent Provider</label>
          <select id="selectProvider" v-model="currentUser.provider" class="form-select" aria-label="Select Provider" :disabled="pageState == 'view'">
                <option value="0" selected>Select a role...</option>
                <option v-for="provider in providers" v-bind:key="provider.id" :value="provider.id">{{ provider.name }}</option>
            </select>
        </div>
        <div class="col">
          <label for="selectRole" class="form-label">Role</label>
          <select id="selectRole" v-model="currentUser.role" class="form-select" aria-label="Select role" :disabled="pageState == 'view'">
            <option value="0" selected>Select a role...</option>
            <option v-bind:value="groupConstants.user">Salesperson</option>
            <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.manager">Manager</option>
            <option v-if="getCurrentUserInRole('Task.Admin')" v-bind:value="groupConstants.admin">Admin</option>
          </select>
        </div>
      </div>
    
    <!-- ACTIONS -->
    <div class="row my-5">
        <div class="col">
            <h2>Actions</h2>
            <button @click="showPageStateEdit" class="btn btn-primary">Edit</button>
        </div>
    </div>

    <!-- DELETE -->
    <div class="row my-5">
        <div class="col">
            <h2>Danger Zone</h2>
            <button @click="deleteUserWarning" class="btn btn-danger">Delete</button>
        </div>
    </div>

    <!-- DELETE MODAL -->
    <div v-if="isDeleteModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete User</h4>
                    <button type="button" class="btn-close" @click="closeDeleteModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p id="UserDeleteText">Are you sure you want to delete <strong>{{ this.currentUser.firstName + ' ' + this.currentUser.lastName }}</strong>?</p>
                    <p>This action is <span class="text-danger">IRREVERSIBLE</span>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDeleteModal" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="deleteUser" data-dismiss="modal">
                        <span>Confirm</span>
                        <span v-show="isDeleting" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isDeleteModalVisible" class="modal-backdrop fade show"></div>
    <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import {mapGetters } from 'vuex';
import { VUE_APP_VSE_USER_GROUP_ID,
    VUE_APP_VSE_MANAGER_GROUP_ID,
    VUE_APP_VSE_ADMIN_GROUP_ID } from '../../utils/constants.js';

export default {
    name: 'UserDetail',
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Users',
                url: '/users'
        }
        ],
        groupConstants: {
            user: VUE_APP_VSE_USER_GROUP_ID,
            manager: VUE_APP_VSE_MANAGER_GROUP_ID,
            admin: VUE_APP_VSE_ADMIN_GROUP_ID
        },
        currentUser: {},
        pageState: 'view',
        isLoading: false,
        isSavedUserLoading: false,
        isUserSaving: false,
        isDeleting: false,
        showSaveSuccess: false,
        showSaveError: false,
        isDeleteModalVisible: false,
        currentName: null,
        currentId: null,
        errorMessages: [],
        errorTitle: 'Error Saving User',
        showInvalidForm: false
    }
  },
  created() {
    console.log('Route ID: ' + this.$route.params.userId);
    this.populateUser();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
        users: 'usersModule/getUsers',
        getCurrentUserInRole: 'getCurrentUserInRole',
        getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
        getDisplayRole: 'usersModule/getDisplayRole',
        providers: "providersModule/getProviders",
    }),
    isProlitecUser() {
        if (this.currentUser.email.split("@")[1] == "prolitec.com") {
                return true;
        } else {
            return false;
        }
    }

  },
  methods: {
    populateUser() {
        var UserByRoute = this.$store.getters['usersModule/getUserById'](this.$route.params.userId);

        this.currentUser = UserByRoute;         

        console.log('init complete...');
        console.log(this.currentUser);
        var newCrumb = { name: this.currentUser.firstName + ' ' + this.currentUser.lastName, url: '/users/' + this.$route.params.userId };
        this.crumbs.push(newCrumb);
        return;
    },
    showPageStateEdit() {
        this.pageState = 'edit'; 
    },
    showPageStateView() {
        this.pageState = 'view';
    },
    async validateAndSaveUser() {
        this.errorMessages = [];
        var isValid = true;
        let existingVseUser = this.getUserByEmailAddress(this.currentUser.email.toLowerCase());
        let userVseExists = existingVseUser != null && existingVseUser.id.length > 0 ? true : false;
        // check for id

        // check for text in short, long description
        if (this.currentUser.firstName == null || this.currentUser.firstName.length == 0) {
            isValid = false;
            this.errorMessages.push('First Name is required.');
        }
        if (this.currentUser.lastName == null || this.currentUser.lastName.length == 0) {
            isValid = false;
            this.errorMessages.push('Last Name is required.');
        }
        // check for selected family, collection
        if (this.currentUser.email == null || this.currentUser.email.length == 0) {
            isValid = false;
            this.errorMessages.push('Email is required.');
        }
        if (this.currentUser.provider == null || this.currentUser.provider.length == 0) {
            isValid = false;
            this.errorMessages.push('Provider is required.');
        }
        if (this.currentUser.role == null || this.currentUser.role.length == 0 || this.currentUser.role == '0') {
            isValid = false;
            this.errorMessages.push('Role is required.');
        }

        //console.log('isValid? ' + isValid + '; errors: ' + this.errorMessages.join(','));

        if (isValid == true) {
            this.isUserSaving = true;
            // check if existing user in Azure AD
            this.$store.dispatch("usersModule/findExistingAdUser", this.currentUser).then((adUser) => {
                let promisesToCall = [];
                console.log(adUser);
                console.log(this.currentUser);
                if (adUser != null && adUser.id.length > 12) {
                    console.log("AD user exists...");
                    this.currentUser.activeDirectoryMemberId = adUser.id;
                    this.currentUser.member = adUser;
                    // user exists in AD...if user exists in Vse.UserSettings, update...else add
                    promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.currentUser));
                    if (userVseExists) {
                        console.log("VSE user exists...");
                        // user exists in VSE...update
                        promisesToCall.push(this.$store.dispatch("usersModule/updateUserSettings", this.currentUser));                           
                    } else {
                        console.log("VSE user does NOT exist...");
                        this.currentUser.joined = new Date();
                        promisesToCall.push(this.$store.dispatch("usersModule/addUserSettings", this.currentUser));
                    }

                    Promise.all(promisesToCall).then((results) => {
                        console.log("Roles updated, vse User added/updated");
                        console.log(results);
                        // redirect to view user with success message
                        this.showSaveSuccess = true;
                        this.isUserSaving = false;
                        this.showPageStateView();
                    });
                } else {
                    console.log("AD user does NOT exist...");
                    // invite user and get ID
                    this.$store.dispatch("usersModule/inviteUser", this.currentUser).then((newUser) => {
                        // user memberId to add to groups
                        this.currentUser.activeDirectoryMemberId = newUser.id;
                        if (userVseExists) {
                            console.log("VSE user exists...");
                            // user exists in VSE...update
                            promisesToCall.push(this.$store.dispatch("usersModule/updateUserSettings", this.currentUser));
                            if (this.user.role != existingVseUser.role) {
                                promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.currentUser));
                            }                           
                        } else {
                            console.log("VSE user does NOT exist...");
                            this.currentUser.joined = new Date();
                            promisesToCall.push(this.$store.dispatch("usersModule/addUserSettings", this.currentUser));
                            promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.currentUser));
                        }
                        Promise.all(promisesToCall).then((results) => {
                            console.log("Roles updated, vse User added/updated");
                            // redirect to view user with success message
                            console.log(results);
                            this.showSaveSuccess = true;
                            this.isUserSaving = false;
                            this.showPageStateView();
                        });
                    })
                    .catch((error) => {
                        console.error("API Error, could not add user. Please try again later.", error);
                        isValid = false;
                        this.errorMessages.push("API Error, could not add user. Please try again later.");
                        this.showInvalidForm = true;
                    });
                }
            });
        } else {
            // show error message
            this.showInvalidForm = true;
        }
    },
    deleteUserWarning() {
        // show modal
        this.isDeleteModalVisible = true;
    },
    async deleteUser() {
        let deletedUserName = this.currentUser.firstName + ' ' + this.currentUser.lastName;
        let deletedUserId = this.currentUser.id;
        console.log('delete user: ' + deletedUserName);
        this.currentUser.role = "0";
        let promisesToCall = [];
        promisesToCall.push(this.$store.dispatch('usersModule/deleteUserSetting', this.currentUser));
        promisesToCall.push(this.$store.dispatch("usersModule/updateUserRoles", this.currentUser));
        this.isDeleting = true;
        Promise.all(promisesToCall).then((results) => {
            console.log("User deleted, roles removed.");
            // redirect to view user with success message
            console.log(results);
            this.closeDeleteModal();
            this.isDeleting = false;
            this.$router.push('/users/' + deletedUserId + '/delete/' + deletedUserName);
        });
    },
    closeDeleteModal() {
        this.isDeleteModalVisible = false;
    },
    onCloseErrorMessageModal() {
        console.log('close error modal');
        this.showInvalidForm = false;
        this.errorMessages = [];
    }
  }
}

</script>
