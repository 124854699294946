<template>
  <section id="ResultList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Quiz History</h1>
            <p>Welcome to the record of past VSE quiz results. 
                To view the recommended fragrances, click “view results”.</p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Results List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
        <thead>
            <tr>
                <th>Date Started</th>
                <th>Date Completed</th>
                <th>Client/Project</th>
                <th>Salesperson</th>
                <th>Invited User</th>
                <th>Invited User Email</th>
                <th>Client</th>
                <th>View Result</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="result in resultsForCurrentUser" :key="result.id">
                <td>{{ formatToShortDate(result.quizStartDate) }}</td>
                <td>{{ formatToShortDate(result.quizCompleteDate) }}</td>
                <td>{{ result.project != null && result.project.name != null ? result.project.name : '-' }}</td>
                <td>{{ result.project != null && result.project.salesperson != null ? result.project.salesperson : '-' }}</td>
                <td>{{ result.invite != null && result.invite.name != null ? result.invite.name : '-'  }}</td>
                <td>{{ result.invite != null && result.invite.email != null ? result.invite.email : '-' }}</td>
                <td>{{ result.invite != null && result.invite.provider != null ? getNameForProviderId(result.invite.provider) : '-' }}</td>
                <td><a :href="'/quiz/' + result.id + '/complete'" class="link link-primary">View Result</a></td>
            </tr>
        </tbody>
        </table>
    </div>
  </section>
</template>

<script>
//import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { formatToShortDate } from '../../utils/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ResultList',
  components: {
    breadcrumbVue,
    jumpNavigation,
  },
  data() {
    return {
      resultsForCurrentUser: [
      ],
      sortedResults: [
      ],
      currentExtendedUser: {},
      currentUserProvider: {},
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Quiz History',
                url: '/results'
      }],
      payload:  '',
      currentSort: '',
      currentSortDir: 'asc',
      isLoading: false
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    this.initListForCurrentUser();
    // load admins first...
    // then add managers
    // then add users
  },
  computed: {
    ...mapGetters({
        allQuizResults: 'quizResultsModule/getQuizResults',
        allUsers: 'usersModule/getUsers',
        getCurrentUserInRole: 'getCurrentUserInRole',
        getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
        account: 'getCurrentUser',
        providers: "providersModule/getProviders",
        providerDetail: 'providersModule/getProviderById',
    }),
  },
  methods: {
    async initListForCurrentUser() {
        this.isLoading = true;
        await this.$store.dispatch("quizResultsModule/fetchQuizResults").then(() => {
            // set current extended user
            console.log(this.account)
            this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
            this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
            console.log(this.currentExtendedUser);
            if (this.getCurrentUserInRole('Task.Admin')) {
                this.resultsForCurrentUser = this.allQuizResults;
            } else if (this.getCurrentUserInRole('Task.Manager')) {
                console.log('Manager...');
                this.resultsForCurrentUser = this.allQuizResults.filter(result => result.invite != null && result.invite.provider == this.currentUserProvider.id);
                console.log(this.resultsForCurrentUser);
                let secondResults = this.allQuizResults.filter(result => result.project != null && result.project.provider == this.currentUserProvider.name);
                console.log(secondResults);
                this.resultsForCurrentUser = this.resultsForCurrentUser.concat(secondResults);
            } else {
                this.resultsForCurrentUser = this.allQuizResults.filter(result => result.project != null && result.project.salesperson != null && result.project.salesperson == this.account.username);
                this.resultsForCurrentUser = this.resultsForCurrentUser.concat(this.allQuizResults.filter(result => result.invite != null && result.invite.salesperson != null && result.invite.salesperson == this.account.username));
            }
            this.isLoading = false;
        });      
    },
    getNameForProviderId(providerId) {
        let _provider = this.providers.find(p => p.id == providerId);
        return _provider != null ? _provider.name : providerId;
    },
    formatToShortDate(date) {
        // let thisDate = new Date(date);
        // console.log(date);
        // console.log(thisDate);
        return formatToShortDate(date);
    }
  }
};
</script>
