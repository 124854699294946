<template>
    <section id="QuizView" class="container">
        <p>
            Please click on the photo below that best represents your business/brand/space in response to each question. 
            Please select one photo in response to each question.
        </p>
        <!-- Load Questions + Answers -->
        <div v-for="(question, index) in currentQuizResult.questions" :key="question.id" :class="questionWrapperByType(question.type)" class="question-item my-4 p-4">
            <div class="row">
                <div class="col">
                    <p class="quiz-question-label text-uppercase">Question #{{ index + 1 }}</p>
                    <div class="quiz-question-text my-3">{{ question.questionText }}</div>
                    <p v-if="question.type == 'Multiple Choice'" class="text-help">Check all that apply.</p>
                </div>
                <div v-if="question.type != 'Photo + Text'" class="col text-end mt-1">
                    <button @click="submitAnswersAndAdvance(question.id)" class="btn btn-primary">
                    <span>Submit</span>
                    <span v-show="isQuestionSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="quiz-answer-list" :class="question.type == 'Photo + Text' ? 'row' : ''">
                <div
                    v-for="answer in question.sourceAnswers.filter((answer) => answer.publishState == 'Published')"
                    :key="answer.id"
                    class="answer-item"
                    :class="answerClassByType(answer.type)"
                >
                    <div v-if="answer.type == 'Photo + Text'" @click="selectPhotoAnswer(question.id, answer.id)" :class="getSelectedClass(question.id,answer.id)" class="ratio ratio-16x9 mb-4 position-relative text-center">
                        <img :src="answer.heroImageUrl" :alt="answer.title" style="width: 100%" />
                        <span class="centered align-self-center" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);display: inline-block; padding: 8px 16px; background-color: #222; color: #fff; width: initial; height: initial;">{{ answer.title }}</span>
                    </div>
                    <div v-else-if="answer.type == 'Single Choice'" :class="getSelectedClass(question.id,answer.id)" class="form-check answer-item-single-choice d-flex align-items-center">
                        <div class="ps-4">
                            <input :id="answer.id" @change.self="selectSingleChoiceAnswer(question.id, answer.id)" :checked="getSelectedState(question.id,answer.id)" :name="question.id" type="radio" :value="answer.title" class="form-check-input" />
                        </div>
                        <div class="flex-fill">
                            <label :for="answer.id" class="form-check-label">{{ answer.title }}</label>
                        </div>
                    </div>
                    <div v-else-if="answer.type == 'Multiple Choice'" :class="getSelectedClass(question.id,answer.id)" class="form-check answer-item-multiple-choice d-flex align-items-center">
                        <div class="ps-4">
                            <input :id="answer.id" @change.self="toggleMultipleChoiceAnswer($event, question.id, answer.id)" :name="question.id" :checked="getSelectedState(question.id,answer.id)" type="checkbox" class="form-check-input" />
                        </div>
                        <div class="flex-fill">    
                            <label :for="answer.id" class="form-check-label">{{ answer.title }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="question.type != 'Photo + Text'" class="row">
                <div class="col text-end mt-3 mb-1">
                    <button @click="submitAnswersAndAdvance(question.id)" class="btn btn-primary">
                        <span>Submit</span>
                    <span v-show="isQuestionSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Transition between Questions -->
        <div class="row">
            <div class="col">
                <button @click="submitQuiz()" class="btn btn-primary">View Recommended Fragrances</button>
            </div>
        </div>
        <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
</template>
<script>
import {mapGetters } from 'vuex';
import errorMessageModal from '../../components/error-message-modal.vue';

export default {
    name: 'QuizView',
    components: {
        errorMessageModal
    },
    data() {
        return {
            pageState: 'question.view', // pageStates: 'question.view', 'question.edit', 'question.delete', 'answer.view', 'answer.add', 'answer.edit', 'answer.delete'
            quizResultState: null,
            isLoading: false,
            isHeroImageLoading: false,
            isAnswerSaving: false,
            isQuestionSaving: false,
            errorMessages: [],
            errorTitle: 'Error Saving Quiz',
            showInvalidForm: false,
            isHeroImageAdded: false,
            selectedQuestions: [],
            currentQuizResult: {
                isQuizStarted: false,
                isQuizCompleted: false,
                permalinkUrl: null,
                quizStartDate: null,
                quizCompleteDate: null,
                invite: {},
                questions: [
                    {
                    id: '',
                    questionText: '',
                    questionType: '',
                    selectedAnswers: [
                        {
                            id: '',
                            title: '',
                            type: '',
                            heroImageUrl: '',
                            adjectives: []
                        }
                    ]
                    }
                ],
                recommendedFragrances: [
                    {
                        recommendedFragranceName: null,
                        recommendedFragranceSku: null,
                        recommendationRating: 0,
                        isModalOpened: false,
                        isOneSheetPdfDownloaded: false
                    }
                ],
                adminRecommendedFragrancesCsvUrl: null,
                adminSelectedAnswersCsvUrl: null
            }
        }
    },
    created() {
        this.init();
    },
    computed: {
         ...mapGetters({
            publishedStateOptions: 'questionsModule/getPublishedStateOptions',
            questionTypeOptions: 'questionsModule/getQuestionTypeOptions',
            activeQuestions: 'questionsModule/getActiveQuestions',
            quizResults: 'quizResultsModule/getQuizResults'
        }),
        currentQuestion() {
            return this.$store.getters['questionsModule/getCurrentQuestion'];
        },

    },
    methods: {
        async init() {
            let promisesToCall = [];
            // init: get active questions
            if (this.activeQuestions == null || this.activeQuestions.length < 1) {
                promisesToCall.push(this.$store.dispatch('questionsModule/fetchQuestions'));
            }

            // init: get quiz results
            if (this.quizResults == null || this.quizResults.length < 1) {
                promisesToCall.push(this.$store.dispatch("quizResultsModule/fetchQuizResults"));
            }
            if (promisesToCall.length > 0) {
            await Promise.all(promisesToCall).then((results) => {
                console.log('Data retrieved...');
                console.log(results);
            });
            } else {
                console.log('We already have data...move on to init phase...');
            }
            // init: get state of current quiz result...if finished, redirect to results page
            this.currentQuizResult = this.quizResults.find(result => result.id == this.$route.params.resultId);
            if (this.currentQuizResult.isQuizCompleted) {
                this.viewRecommendedFragrances();
            }

            // loop through current questions and add question ID, question Type, question Text to current Result
            if (this.currentQuizResult.questions == null || this.currentQuizResult.questions.length < 1) {
                this.activeQuestions.forEach((question) => {
                    console.log({ question });
                    var mappedQuestion = {
                        id: question.id,
                        type: question.type,
                        questionText: question.questionText,
                        selectedAnswers: [],
                        sourceAnswers: []
                    };
                    question.answers.forEach((answer) => {
                        var newSourceAnswer = {
                            id: answer.id,
                            type: answer.type,
                            title: answer.title,
                            publishState: answer.publishState,
                            heroImageUrl: answer.heroImageUrl
                        }
                        mappedQuestion.sourceAnswers.push(newSourceAnswer);
                    });
                    this.currentQuizResult.questions.push(mappedQuestion);
                });
            }

            // init: set up quiz with any existing state

            // display current question...

        // addAnswer / updateAnswer

        // auto Transition to next question (consider option for going back?)

        // auto Transition to Results (consider option for going back?)
        },
        async setCurrentQuizResult() {
            if (this.quizResults == null || this.quizResults.length == 0) {
                return await this.$store.dispatch("quizResultsModule/fetchQuizResults").then(() => {
                    this.currentQuizResult = this.quizResults.find(result => result.id == this.$route.params.resultId);
                    console.log('Fetching quiz results...');
                    console.log(this.currentQuizResult);
                    let newQuestions = this.activeQuestions;
                    console.log(newQuestions);
                    for (var question in newQuestions) {
                        question.selectedAnswers = [];
                    }
                    console.log(newQuestions);
                    this.currentQuizResult.questions = newQuestions;
                });
            } else {
                console.log('Finding matching quiz result...');
                this.currentQuizResult = this.quizResults.find(result => result.id == this.$route.params.resultId);
                console.log(this.currentQuizResult);

                if (this.currentQuizResult.questions.length == 0) {
                    var newQuestions = [];
                    this.activeQuestions.forEach((question) => {
                        console.log(question);
                        var newQuestion = {
                            questionText: question.questionText,
                            id: question.id,
                            type: question.type,
                            sourceAnswers: [],
                            selectedAnswers: []
                        }
                        
                        this.currentQuizResult.questions.push(newQuestion);
                    });
                    console.log(newQuestions);
                    this.currentQuizResult.questions = newQuestions;
                }
                
            }
            return;
        },
        questionWrapperByType(questionType) {
            var className = '';
            switch (questionType) {
                case 'Photo + Text':
                    className = 'question-photo';
                    break;
                case 'Single Choice':
                    className = 'question-single-choice';
                    break;
                case 'Multiple Choice':
                    className = 'question-multiple-choice';
                    break;
                default:
                    className = 'question-single-choice';
            }
            return className;
        },
        answerClassByType(answerType) {
            var className = '';
            switch (answerType) {
                case 'Photo + Text':
                    className = 'answer-item-photo col-md-6 col-sm-12';
                    break;
                case 'Single Choice':
                    className = 'answer-item-text col-xl-12';
                    break;
                case 'Multiple Choice':
                    className = 'answer-item-text col-xl-12';
                    break;
                default:
                    className = 'col-xl-12';
            }
            return className;
        },
        viewAnswer(index) {
            this.pageState = 'answer.view';
            this.currentAnswer = this.currentQuestion.answers[index];
            this.addAnswerCrumb();
        },
        getSelectedClass(questionId, answerId) {
            var unSelectedClass = '';
            if (this.selectedQuestions.includes(questionId)) {
                // question is selected, set inactive class = 'inactive'
                unSelectedClass = 'inactive';
            }
            return this.getSelectedState(questionId, answerId) ? "active" : unSelectedClass;
        },
        getSelectedState(questionId, answerId) {
            var selectedQuestion = this.currentQuizResult.questions.find(question => question.id == questionId);
            return selectedQuestion.selectedAnswers.includes(answerId);
        },
        selectPhotoAnswer(questionId, answerId) {
            // console.log({questionId});
            // console.log({answerId});
            if (!this.selectedQuestions.includes(questionId)) {
                this.selectedQuestions.push(questionId);
            }
            var selectedQuestion = this.currentQuizResult.questions.find(question => question.id == questionId);
            console.log(selectedQuestion);
            selectedQuestion.selectedAnswers = [ answerId ];
        },
        selectSingleChoiceAnswer(questionId, answerId) {
            console.log({questionId});
            console.log({answerId});
            if (!this.selectedQuestions.includes(questionId)) {
                this.selectedQuestions.push(questionId);
            }
            var selectedQuestion = this.currentQuizResult.questions.find(question => question.id == questionId);
            selectedQuestion.selectedAnswers = [ answerId ];
        },
        toggleMultipleChoiceAnswer(event, questionId, answerId) {
            console.log({event});
            console.log({questionId});
            console.log({answerId});
            
            if (!this.selectedQuestions.includes(questionId)) {
                this.selectedQuestions.push(questionId);
            }
            var selectedQuestion = this.currentQuizResult.questions.find(question => question.id == questionId);
            let selectedAnswerIndex = selectedQuestion.selectedAnswers.indexOf(answerId);
            if (selectedAnswerIndex != null & selectedAnswerIndex > -1) {
                console.log('answer found...removing it...');
                selectedQuestion.selectedAnswers.splice(selectedAnswerIndex, 1);
            } else {
                selectedQuestion.selectedAnswers.push(answerId);
                console.log('answer NOT found...adding it');
            }
            
        },
        async submitQuiz() {
            console.log(this.currentQuizResult);
            this.isQuestionSaving = true;
            var isQuizValid = true;
            // verify all of the questions have the corrected # of selected answers...
            this.currentQuizResult.questions.forEach((question) => {
                if (!this.validateQuestion(question.id)) {
                    isQuizValid = false;
                }
            });
  
            console.log(isQuizValid);
            // if valid, add completed values to quiz result and update...
            if (isQuizValid) {
                console.log('Updating results...');
                this.currentQuizResult.isQuizCompleted = true;
                this.currentQuizResult.quizCompleteDate = new Date();
                await this.$store.dispatch('quizResultsModule/updateQuizResult', this.currentQuizResult).then(() => {
                    this.isQuestionSaving = false;
                    // redirect to Quiz Complete view with Recommendations...
                    this.viewRecommendedFragrances();
                });
            } else {
                // show answer all questions modal
                this.showInvalidForm = true;
                this.errorMessages.push('You must select an answer for each question.');
            }
            this.isQuestionSaving = false;
        },
        async submitAnswersAndAdvance(questionId) {
            console.log('Saving progress...' + questionId);
            console.log(this.currentQuizResult);
            this.isQuestionSaving = true;

            // verify the question clicked has at least 1 selected answer...
            if (this.validateQuestion(questionId)) {
                console.log('Updating results...');
                await this.$store.dispatch('quizResultsModule/updateQuizResult', this.currentQuizResult).then(() => {
                    this.isQuestionSaving = false;
                });
            }
            this.isQuestionSaving = false;
        },
        validateQuestion(questionId) {
            var isQuestionValid = false;
            let currentQuestionSubmitted = this.currentQuizResult.questions.find(question => question.id == questionId);

            if (currentQuestionSubmitted.selectedAnswers != null) {
                console.log('Checking responses...');
                if (currentQuestionSubmitted.type == 'Photo + Text' && currentQuestionSubmitted.selectedAnswers.length == 1) {
                    console.log('Photo + Text & length: ' + currentQuestionSubmitted.selectedAnswers.length);
                    isQuestionValid = true;
                } else if (currentQuestionSubmitted.type == 'Single Choice' && currentQuestionSubmitted.selectedAnswers.length == 1) {
                    isQuestionValid = true;
                } else if (currentQuestionSubmitted.type == 'Multiple Choice' && currentQuestionSubmitted.selectedAnswers.length > 0) {
                    isQuestionValid = true;
                }
            }

            return isQuestionValid;
        },
        validateCurrentQuizResult() {
            var isValid = true;
            if (this.currentAnswer == null) {
                isValid = false;
            }
            return isValid;
        },
        toggleAnswer(questionId, answer) {
            //var targetQuestionIndex = this.quizResultState.questions.findIndex(question => question.id == questionId);
            console.log(questionId + answer);
            //this.quizResultState.questions[targetQuestionIndex].answerTitle = answer.title;
        },
        showPageState(pageState) {
            this.pageState = pageState;
            if (pageState.includes('uestion')) {
                this.removeAnswerCrumb();
            }
        },
        showPageStateView() {
            this.pageState = 'view';
        },
        viewRecommendedFragrances() {
            this.$router.push('/quiz/' + this.currentQuizResult.id + '/complete');
        },
        onCloseErrorMessageModal() {
            console.log('close error modal');
            this.showInvalidForm = false;
            this.errorMessages = [];
        }
    }
}
</script>
