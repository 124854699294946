<template>
  <chrome-picker v-model="activeColor" @change="colorChanged" disableAlpha="true"></chrome-picker>
</template>
<script>
import { Chrome } from '@ckpack/vue-color';

export default {
    name: 'FragranceColorPicker',
    components: {
        'chrome-picker': Chrome
    },
    data() {
        return {
        activeColor: '#F8B133', // etc: { h: 150, s: 0.66, v: 0.30 }, { r: 255, g: 0, b: 0 }, '#194d33'
        updateValue: null
        }
    },
    mounted () {
        this.$emit('colorChange', this.activeColor);
    },
    methods: {
        colorChanged() {
            console.log('colors change detected...');
        }
    },
    watch:
    {
        activeColor(newVal, oldVal)
        {
            //console.log('colors change detected...');
            //console.log(newVal);
            //console.log(newVal.hex);
            // this check is mandatory to prevent endless cycle
            if(newVal !== oldVal) this.$emit('colorChange', newVal.hex);
            //console.log('Color changed! From ' + oldVal + ' to: ' + newVal);
        }
        // activeColor: {
        //   handler(newVal, oldVal) {
        //     console.log('colors change detected...');
        //     console.log('Color changed! From ' + oldVal + ' to: ' + newVal);
        //     console.log(newVal);
        //     // Note: `newValue` will be equal to `oldValue` here
        //     // on nested mutations as long as the object itself
        //     // hasn't been replaced.
        //   },
        //   deep: true
        //}
    }
}
</script>