import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Emitter from 'tiny-emitter';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';


const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
// TODO: make the emitter a service
app.config.globalProperties.$emitter = new Emitter();

app.use(store).use(router).use(BootstrapIconsPlugin).mount('#app');
