<!-- DISPLAY LIST OF ADJECTIVES ASSOCIATED WITH A FRAGRANCE -->
<!-- ADJECTIVE ADMIN LIST -->
<!-- properties: mode (display/edit), id, name, includeWeight?, weight, active, selected, disabled, sort order, actions: add/remove/changeOrder -->
<template>
    <div class="tag-list">
        <a v-for="adjective in displayAdjectives" :key="adjective.id" class="btn btn-tag mb-2 me-2" role="button">{{ adjective.name }}</a>
    </div>
</template>

<script>

export default {
  name: 'AdjectiveDisplayList',
  components: { },
  props: {
    displayAdjectives: {
        type: Array
    }
  },
  //adjectiveItems: [],
  isSortable: false,
  isDraggable: false,

};
</script>
