<template>
    <section id="SignInOut">
    <a class="nav-link link link-primary"
        v-if="!user"
        @click="loginUser"
        rel="noopener noreferrer"
    >Sign In<span class="text-primary ms-1"><BIconBoxArrowInRight /></span></a>
    <span v-else>
        <a class="link link-primary" @click="shallowLogout" href="#" rel="noopener noreferrer">Sign Out</a>
        <span class="text-primary ms-1"><BIconBoxArrowRight /></span>
    </span>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import auth from "../services/auth";
import { VUE_APP_MSAL_CLIENT_ID } from '../utils/constants.js';
//import graph from "../services/graph";

export default {
    name: "SignInOut",
    components: {  },
    data() {
        return {
            isSignInProcessing: false,
            signInErrorMsgVisible: false,
            signOutMsgVisible: false,
            signin: "https://microsoft.com",
            // User account object synced with MSAL getAccount()
            // Access token fetched via MSAL for calling Graph API
            accessToken: "",
            error: "",
        };
    },
    watch: {
        // If user changes, make calls to Graph API
        // user: async function () {
        //     this.fetchGraphDetails();
        // },
    },
    async created() {
        console.log('Component created...');
        // Basic setup of MSAL helper with client id, or give up
        if (VUE_APP_MSAL_CLIENT_ID) {
            auth.configure(VUE_APP_MSAL_CLIENT_ID, false);
            // Restore any cached or saved local user
            this.$store.commit('setAccount', auth.user());
            console.log(`configured ${auth.isConfigured()}`);
        } else { 
            this.error = "VUE_APP_MSAL_CLIENT_ID is not set, the app will not function! 😥";
            console.log(this.error);
        }
    },
    mounted() {},
    computed: {
        ...mapGetters ({
            user: 'getCurrentUser'
        })
    },
    methods: {
        // Update user from MSAL
        async loginUser() {
            console.log('MSAL login start...');
            try {
                await auth.login().then(() => {
                    this.updateUser();
                    // 11 Nov 22 - redirect to dashboard per H. Lane
                    this.$router.push('/dashboard');
                });
            } catch (err) {
                this.error = err.toString()
            }
        },
        updateUser() {
            this.$store.commit('setAccount', auth.user());
        },
        // Remove locally held user details, is same as logout
        shallowLogout() {
            this.$store.commit('clearAccount');
            auth.clearLocal();
            this.$router.push('/');
        },
        // Full logout local & server side
        fullLogout() {
            this.$store.commit('clearAccount');
            auth.logout();
        },
    }
};
</script>