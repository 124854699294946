<template>
    <section id="FragranceView" class="container">
        <div class="row">
            <div class="col">
                <h1>Deleted {{ deletedFragranceName }}</h1>
                <p>{{ deletedFragranceName }} has been deleted.</p>
                <router-link to="/fragrances" class="link link-primary">Return to Fragrances</router-link>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: 'FragranceList',
  data() {
    return {
      deletedFragranceName:  '',
    }
  },
  mounted() {
    this.deletedFragranceName = this.$route.params.fragrance;
    console.log(this.deletedFragranceName);
  }
}
</script>
