export const loader = {
    namespaced: true,
    state: {
        loading: false
    },
    getters: {
        isLoading(state) {
            return state.loading;
        }
    },
    actions: {
        show({ commit }) {
            console.log('Loader.Show');
            commit("show");
        },
        hide({ commit }) {
            commit("hide");
        }
    },
    mutations: {
        show(state) {
            state.loading = true;
            console.log('Loader.Show (mutation)');
        },
        hide(state) {
            state.loading = false;
        }
    }
};