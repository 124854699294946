import { createWebHistory, createRouter } from "vue-router";
import Invite from "@/views/Invite.vue";
import Login from "@/views/Login.vue";
import Start from "@/views/Start.vue";
import Quiz from "@/views/quiz/Quiz.vue";
import QuizComplete from "@/views/quiz/QuizComplete.vue";
import StyleGuide from "@/views/StyleGuide.vue";
import Dashboard from "@/views/Dashboard.vue";
import UserList from "@/views/users/ListUsers.vue";
import UserAdd from "@/views/users/AddUser.vue";
import UserDelete from "@/views/users/DeleteUser.vue";
import UserDetail from "@/views/users/ViewUser.vue";
import AdjectiveList from "@/views/adjectives/ListAdjectives.vue";
import AdjectiveAdd from "@/views/adjectives/AddAdjective.vue";
import AdjectiveDelete from "@/views/adjectives/DeleteAdjective.vue";
import AdjectiveDetail from "@/views/adjectives/ViewAdjective.vue";
import FragranceList from "@/views/fragrances/ListFragrances.vue";
import FragranceAdd from "@/views/fragrances/AddFragrance.vue";
import FragranceDelete from "@/views/fragrances/DeleteFragrance.vue";
import FragranceDetail from "@/views/fragrances/ViewFragrance.vue";
import InviteList from "@/views/invites/ListInvites.vue";
import ProviderList from "@/views/providers/ListProviders.vue";
import ProviderAdd from "@/views/providers/AddProvider.vue";
import ProviderDelete from "@/views/providers/DeleteProvider.vue";
import ProviderDetail from "@/views/providers/ViewProvider.vue";
import ProviderInventory from "@/views/providers/ViewInventory.vue";
import QuestionList from "@/views/questions/ListQuestions.vue";
import QuestionAdd from "@/views/questions/AddQuestion.vue";
import QuestionDelete from "@/views/questions/DeleteQuestion.vue";
import QuestionUpdate from "@/views/questions/UpdateQuestion.vue";
import QuestionDetail from "@/views/questions/ViewQuestion.vue";
import ResultList from "@/views/results/ListResults.vue";
import ResultDetail from "@/views/results/ViewResult.vue";
import ResultDownloadAnswers from "@/views/results/DownloadAnswerResults.vue";
import ResultDownloadFragrances from "@/views/results/DownloadFragranceResults.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/invite",
    name: "Invite",
    component: Invite,
  },
  {
    path: "/start",
    name: "Start",
    component: Start,
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: Quiz,
  },
  {
    path: "/quiz/:resultId",
    name: "QuizForInvite",
    component: Quiz,
  },
  {
    path: "/quiz/:resultId/complete",
    name: "QuizRecommendation",
    component: QuizComplete,
  },
  {
    path: "/style-guide",
    name: "Style Guide",
    component: StyleGuide,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/users",
    name: "UsersList",
    component: UserList
  },
  {
    path: "/users/add",
    name: "UserAdd",
    component: UserAdd
  },
  {
    path: "/users/:userId/delete/:userName",
    name: "UserDelete",
    component: UserDelete
  },
  {
    path: "/users/:userId",
    name: "UserDetail",
    component: UserDetail
  },
  {
    path: "/adjectives",
    name: "AdjectivesList",
    component: AdjectiveList
  },
  {
    path: "/adjectives/add",
    name: "AdjectiveAdd",
    component: AdjectiveAdd
  },
  {
    path: "/adjectives/:adjectiveId/delete",
    name: "AdjectiveDelete",
    component: AdjectiveDelete
  },
  {
    path: "/adjectives/:adjectiveId",
    name: "AdjectiveDetail",
    component: AdjectiveDetail
  },
  {
    path: "/fragrances",
    name: "FragrancesList",
    component: FragranceList
  },
  {
    path: "/fragrances/add",
    name: "FragranceAdd",
    component: FragranceAdd
  },
  {
    path: "/fragrances/:fragranceId",
    name: "FragranceDetail",
    component: FragranceDetail
  },
  {
    path: "/fragrances/delete/:fragrance",
    name: "FragranceDelete",
    component: FragranceDelete
  },
  {
    path: "/fragrance-inventory/:providerId",
    name: "ProviderInventory",
    component: ProviderInventory
  },
  {
    path: "/providers",
    name: "ProvidersList",
    component: ProviderList
  },
  {
    path: "/providers/add",
    name: "ProviderAdd",
    component: ProviderAdd
  },
  {
    path: "/providers/:providerId/delete",
    name: "ProviderDelete",
    component: ProviderDelete
  },
  {
    path: "/providers/:providerId",
    name: "ProviderDetail",
    component: ProviderDetail
  },
  {
    path: "/invites",
    name: "InvitesList",
    component: InviteList
  },
  {
    path: "/questions",
    name: "QuestionsList",
    component: QuestionList
  },
  {
    path: "/questions/add",
    name: "QuestionAdd",
    component: QuestionAdd
  },
  {
    path: "/questions/:questionId/delete/:questionText",
    name: "QuestionDelete",
    component: QuestionDelete
  },
  {
    path: "/questions/:questionId/update",
    name: "QuestionUpdate",
    component: QuestionUpdate
  },
  {
    path: "/questions/:questionId",
    name: "QuestionDetail",
    component: QuestionDetail
  },
  {
    path: "/results",
    name: "ResultsList",
    component: ResultList
  },
  {
    path: "/results/:resultId",
    name: "ResultDetail",
    component: ResultDetail
  },
  {
    path: "/results/:resultId/download-answers",
    name: "ResultDownloadAnswers",
    component: ResultDownloadAnswers
  },
  {
    path: "/results/:resultId/download-fragrances",
    name: "ResultDownloadFragrances",
    component: ResultDownloadFragrances
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('to:' + to + ';from:' + from + 'saved:' + savedPosition);
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
