<template>
    <section id="UserList" class="container">
        <breadcrumbVue :items="crumbs"></breadcrumbVue>
        <h1>Add Provider</h1>
        <p>Enter new scent provider information below. 
            De-select any fragrances that should not be enabled for their catalog (all fragrances enabled by default),
            and click “add provider.”</p>
        <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <!-- User Form -->
        <form @submit.prevent="submitAddProvider">
            <div class="row my-3">
                <div class="col">
                    <button @click="returnToList" class="btn btn-secondary me-2">Cancel</button>
                    <button class="btn btn-primary" type="submit">
                        <span>Add Provider</span>
                        <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="row my-5">
                <label class="visually-hidden" for="ProviderName">Name</label>
                <input id="ProviderName" v-model="provider.name" class="form-control" type="text" placeholder="Name" aria-label="Name" />
            </div>
            <!-- FRAGRANCES -->
            <div class="row my-5">
                <h2>Fragrance Inventory</h2>
                <div>
                    <a class="link link-primary" @click="selectAllFragrances">Select All</a> |
                    <a class="link link-primary" @click="deselectAllFragrances">Deselect All</a>
                </div>
                <ul v-if="allFragrances != null && allFragrances.length > 0" class="list-unstyled card-columns">
                <li v-for="(fragrance) in allFragrances" :key="fragrance.id" class="p-2 mb-2">
                    <div class="form-check form-switch">
                        <!-- Checkbox -->
                        <input class="form-check-input" :id="'flexSwitchCheckDefault_' + fragrance.id" value="{{ fragrance.id }}" type="checkbox" role="switch" @change="toggleFragranceSelection(fragrance.id)"  :checked="isFragranceSelected(fragrance.id)">
                        <!-- Name of Fragrance -->
                        <label class="form-check-label notranslate" :for="'flexSwitchCheckDefault_' + fragrance.id">{{ fragrance.name }}</label>
                    </div>
                </li>
            </ul>
            </div>         
        </form>
        <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
    </section>
</template>

<script>
import breadcrumbVue from "../../components/breadcrumb.vue";
import errorMessageModal from '../../components/error-message-modal.vue';
import { mapGetters } from "vuex";

export default {
    components: {
        breadcrumbVue,
        errorMessageModal
    },
    data() {
        return {
            crumbs: [
                {
                    name: "Dashboard",
                    url: "/dashboard",
                },
                {
                    name: "Providers",
                    url: "/providers",
                },
                {
                    name: "Add Provider",
                    url: "/providers/add",
                },
            ],
            provider: {
                id: '',
                name: '',
                fragrances: []
            },
            formVisible: false,
            sourceFragrances: [],
            currentErrorMsg: '',
            isValidForm: true,
            isLoading: false,
            isSaving: false,
            invalidInput: false,
            errorMessages: [],
            errorTitle: 'Error Adding Provider',
            showInvalidForm: false,
        };
    },
    computed: {
        ...mapGetters({
        allFragrances: 'fragrancesExtendedModule/getFragrancesExtended',
        getCurrentUserInRole: 'getCurrentUserInRole',
    }),
  },
  mounted() {
    this.initAddProvider();
  },
  methods: {
    initAddProvider() {
        this.provider.fragrances = this.allFragrances.map((allFragrances) => ({
            id: allFragrances.id
        }));
    },
    selectAllFragrances() {
        console.log('select all...');
        this.provider.fragrances = this.allFragrances.map((allFragrances) => ({
            id: allFragrances.id
        }));
    },
    deselectAllFragrances() {
        console.log('deselect all...');
        this.provider.fragrances = [];
    },
    isFragranceSelected(fragranceId) {
        if (this.provider.fragrances != null && this.provider.fragrances.length > 0) {
            let index = this.provider.fragrances.findIndex(f => f.id == fragranceId);
            if (index > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    toggleFragranceSelection(fragranceId) { 
        console.log(fragranceId);
        let index = this.provider.fragrances.findIndex(f => f.id == fragranceId);
        if (index > -1) {
            console.log('found...remove it');
            // it's found - remove it
            this.provider.fragrances.splice(index, 1);
        } else {
            // add the fragrance
            console.log('not found...add it');
            this.provider.fragrances.push({id: fragranceId});
        }
    },
    async submitAddProvider() {
        this.isValidForm = true;
        this.currentErrorMsg = '';
        console.log('provider: ' + this.provider);

        if (this.provider.name.length < 1) {
            this.isValidForm = false;
            this.errorMessages.push('Provider name is required.'); 
        }

        if (this.provider.fragrances == null || this.provider.fragrances.length < 1) {
            this.isValidForm = false;
            this.errorMessages.push('At least 1 Fragrance(s) is required.');
        } 
        
        if (this.isValidForm) {
            this.isSaving = true;
            // update
            console.log('update provider...');
            await this.$store.dispatch('providersModule/addProvider', this.provider).then((results) => {
                // add saved success message
                this.showSaveSuccess = true;
                this.isSaving = false;
                console.log(results);
                let providerId = results;
                if (providerId != null) {
                    this.$router.push("/providers/" + providerId);
                } else {
                    this.$router.push("/providers");
                }
                
            });
        } else {
            // show error message
            console.log(this.errorMessages);
            this.showInvalidForm = true;
        }
    },
    toggleProviderForm() {
        this.formVisible = this.formVisible == true ? false : true;
    },
    hideProviderForm() {
        this.formVisible = false;
        this.currentErrorMsg = '';
    },
    clearFormFields() {
        this.provider.id = '';
        this.provider.name = '';
        this.currentErrorMsg = '';
    },
    returnToList() {
            this.$router.push('/providers/');
        },
    onCloseErrorMessageModal() {
            console.log("close error modal");
            this.showInvalidForm = false;
            this.errorMessages = [];
        },
  }
};
</script>